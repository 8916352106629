import React from 'react';
import { DomainChangePage } from 'src/routes/domain-change/DomainChangePage';

function action() {
  return {
    chunks: ['domain-change'],
    component: <DomainChangePage />,
  };
}

export default action;
