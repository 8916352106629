import React from 'react';
import { Heading, Body, Button } from 'copilot-design-system';
import { COPILOT_GUIDE_URL } from 'src/constants/externalUrls';
import { RouteContext } from 'src/context';
import { copilotApp } from 'src/constants/hostnameConsts';

export const DomainChangePage = () => {
  const {
    query: { next },
  } = React.useContext(RouteContext);

  const handleGoToDashboard = () => {
    window.location.href = `https://dashboard.${copilotApp.prod}${next}`;
  };

  return (
    <div className="min-h-screen max-w-[720px] text-center flex flex-col items-center justify-center m-auto">
      <img src="/images/location_map.png" alt="Location changed" width="280" />
      <div className="mt-[35px]">
        <Heading size="2xl" className="leading-8">
          The Copilot Dashboard has a new location
        </Heading>
      </div>
      <div className="mt-4">
        <Body size="lg">
          The Copilot Dashboard is now live at{' '}
          <span>
            dashboard.copilot.<b>app</b>
          </span>{' '}
          (formerly dashboard.copilot.com). The old URL will be retired soon, so
          please update your bookmarks. You might need to log in again. No
          changes to your client portal — your clients won't notice a thing.
        </Body>
      </div>
      <Button
        variant="primary"
        type="button"
        label="Go to dashboard.copilot.app"
        onClick={handleGoToDashboard}
        className="mt-8"
      />
      <a
        href={`${COPILOT_GUIDE_URL}/new-copilot-url`}
        target="_blank"
        rel="noopener noreferrer"
        className="no-underline mt-4 text-gray-small"
      >
        <Body>Learn more</Body>
      </a>
    </div>
  );
};
